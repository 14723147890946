import { logEvent,getAnalytics,setUserProperties } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyB13J7NKxjfhdQ9zbeq9-38idcHqMjcqM0",
  authDomain: "classteam-org.firebaseapp.com",
  projectId: "classteam-org",
  storageBucket: "classteam-org.firebasestorage.app",
  messagingSenderId: "659944965868",
  appId: "1:659944965868:web:42f9ebdce4849bd4ab07c9",
  measurementId: "G-VK3YTF0DVY"
  };
  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  
  export const logEventWeb = async (eventName: string, params: Record<string, any>) => {
    try {
      logEvent(analytics, eventName, params);
       console.log(`Event logged: ${eventName}`, params);
    } catch (error) {
      console.error("Error logging event:", error);
    }
  };
  
  export const setUserPropertiesWeb = (params: Record<string, string>) => {
    try {
      setUserProperties(analytics, params);
      // console.log("User properties set:", params);
    } catch (error) {
      console.error("Error setting user properties:", error);
    }
  };